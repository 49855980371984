import React from 'react';
import { Col} from 'reactstrap';

const RectMetr = () => {
    return (
        <>
            м<sup><small>2</small> </sup>
        </>
    )
}

const LineMetr = () => {
    return (
        <>
            м/п
        </>
    )
}


const PriceList = (props) => {
    const {priceList} = props;
    const listOfPice = priceList.map((itemProps, i) => {
        return (
            <tr key={i}>
                <th scope="row">{i}</th>
                    <td>{itemProps.name}</td>
        <td>от <span className="price-list">{itemProps.price}</span> рублей за {itemProps.units === 2? <RectMetr />: <LineMetr />}</td>
            </tr>
        )
    })
    return (
        <>
             <Col md="12">
                    <table className="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col">№</th> 
                        <th scope="col">Услуга</th>
                        <th scope="col">Цена</th>
                    </tr>
                    </thead>
                    <tbody>
                        {listOfPice}
                    </tbody>
                </table>
              </Col>
        </>
    )
}

export default PriceList;