import React from 'react';
import TextToggle from '../../components/text-toggle/text-toggle';
import WoodHome from '../../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import Advantages from '../../components/advantages/advantages';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import Data from '../../data/data';
import PriceList from '../../components/price-list/price-list';


const RestavratsiyaDerevyannogoPola = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Реставрация деревянных домов', url:'/restavratsiya-derevyannyh-domov/', status:true},
    {name:'Замена деревянного пола', url:'#', status:true}]
  return (
    <div>
        <SEO title="Замена деревянного пола в Калининградской области"
            description="Опытные бригады в срок выполнят работы по реставрации деревянного пола в Калининградской области.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Замена деревянного пола в Кали&shy;нинград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-md-left">Замена деревянного пола</h1>
                <p>
                Деревянные полы и лаги не вечные и время берет свое, но когда пол первого этажа в еще не старом доме начинают проваливаться уже через 5-7 лет – значит что то пошло не так, значит причины весьма весомы и есть проблемы с их устройством.
               </p>
                  
                  
               
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
        <Advantages />
         </Container>
        
           
        <FormQuickOrder className="mt-5" />
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default RestavratsiyaDerevyannogoPola;