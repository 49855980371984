
import Zolla from '../images/goats/alpiyskaia_poroda/alpiyskaia_koza_680x680.jpg';

const goatsList =  [
  //  {name:'Garry', price:'4000', url:'Garry', featureImage:`${Zolla}`,  type:'zaanen', breed:'Зааненская', currency:'руб', pedigree: 4,
  //  photos:[
  //      {
  //          src: Zolla,
  //          width: 3,
  //          height: 3,
  //          alt: ''
  //        },
  //        {
  //          src: Zolla,
  //          width: 3,
  //          height: 3,
  //          alt: ''
  //        },
  //        {
  //          src: Zolla,
  //          width: 3,
  //          height: 3,
  //          alt: ''
  //        }
  //  ], description:'авно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации "Здесь ваш текст.. Здесь ваш текст.. Здесь ваш текст.."'},
  //  {name:'Garry', price:'4000', url:'Garry', featureImage:`${Zolla}`,  type:'zaanen', breed:'Зааненская', currency:'руб', pedigree: 5,
  //  photos:[
  //      {
  //          src: Zolla,
  //          width: 3,
  //          height: 3,
  //          alt: ''
  //        },
  //        {
  //          src: Zolla,
  //          width: 3,
  //          height: 3,
  //          alt: ''
  //        },
  //        {
  //          src: Zolla,
  //          width: 3,
  //          height: 3,
  //          alt: ''
  //        }
  //  ], description:'авно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное распределение букв и пробелов в абзацах, которое не получается при простой дубликации "Здесь ваш текст.. Здесь ваш текст.. Здесь ваш текст.."'},
  //     
],

    breedList = []
   
export {goatsList, breedList};
